'use client';

import { useTranslation } from '@/i18n/client';
import Link from 'next/link';
import { Button, Container } from 'react-bootstrap';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const NotFoundPage = ({lang}) => {
    const { t } = useTranslation(lang);
    return (
        <>
            <div className="error-page min-vh-100 d-flex">
                <Container fluid={'lg'} className="my-auto">
                    <div className="lk-error-page">
                        <LazyLoadImage
                            className="img-fluid"
                            src="https://cdn.1cdn.app/application/LUMBERJACK/2208240651_404_1.svg"
                            alt="L-K.io"
                            height={400}
                            width={400}
                        />
                        <h2 className="text-center">{t('NotFoundPage/key2')}</h2>
                        <p className="text-center">{t('NotFoundPage/key3')}</p>
                        <Link href={`/`}>
                            <Button variant="warning" className="rounded-3 text-white">
                                {t('NotFoundPage/key1')}
                            </Button>
                        </Link>
                    </div>
                </Container>
            </div>
        </>
    );
};

export default NotFoundPage;
